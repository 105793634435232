<template>
  <div :class="['limit-content',item.active ? 'did-select' : item.canUse ? 'did-not' : 'did-get']" :style="contentStyle"
    v-for="(item,index) in canGetDisCoupons" :key="index" @click="handeSelect(item)">
    <div class="limit-left">
      <div class="limit-price">
        
        <div class="price" v-if="item.type==2"><span>HK$</span>{{ item.price }}</div>
        <div class="price" v-else>{{ item.discount }}%OFF</div>
      </div>
      <div class="max-conditions">
        <div v-if="langEnv == '3'">
          {{ $t("滿") }} {{ item.metPrice }} {{ $t("可用") }}
        </div>
        <div v-else>
          {{ $t("可用") }} {{ $t("滿") }} {{ item.metPrice }}
        </div>
      </div>
    </div>
    <div class="limit-right">
      <div>
        <div class="title">
          <div class="left">
            {{ item.name }}
          </div>
        </div>
        <div class="bottom">
          <div class="left">{{ $t('有效期至') }} <span>{{ item.expiredDate }}</span></div>
        </div>
        <div class="question" v-if="item.isAllUse">
          <div v-if="item.isAllUse">{{ $t("適用於所有的產品") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from "@/utils/token.js"
export default {
  name: "limit-coupon-layout",
  props: {
    list: {
      type: Array,
      default: []
    },
    contentStyle: {
      type: String,
      default: ""
    }
  },
  watch: {
    list: {
      handler (val) {
        this.canGetDisCoupons = val
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      langEnv: null,
      Tagtype: null,
      canGetDisCoupons: [],
    }
  },
  mounted () {
    this.langEnv = localStorage.getItem("langEnv") || 2
  },
  methods: {
    handeSelect (row) {
      if (!row.canUse) return
      this.$emit('change',row)
    }
  }
}
</script>
<style scoped lang="scss">
.limit-content {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 343px;
  min-height: 92px;
  position: relative;
  border-radius: 12px;
  margin-bottom: 16px;
}

.did-not {
  background: url("~@/assets/image/coupon/mycoupon.png") no-repeat;
  background-size: 100% 100%;
}

.did-get {
  background: url("~@/assets/image/coupon/efficacy.png") no-repeat;
  background-size: 100% 100%;
}

.did-select {
  background: url("~@/assets/image/coupon/active-mycoupon.png") no-repeat;
  background-size: 100% 100%;
  min-height: 118px;
}

.limit-content img {
  width: 100%;
}

.limit-left {
  // padding-left: 12px;
  width: 31%;
}

.limit-left .limit-price {
  font-size: 18px;
  color: #e84935;
  font-weight: 700;
  // display: flex;
  // align-items: end;
  
  line-height: 1;
  margin-bottom: 4px;

  span {
    font-size: 12px;
  }

  .price {
    text-align: center;
    font-size: 18px;

    span {
      font-size: 12px;
    }
  }
}

.did-get .limit-left .limit-price,
.did-select .limit-left .limit-price {
  color: #000000;
}

.did-get .limit-left .max-conditions,
.did-select .limit-left .max-conditions {
  color: #000000;
}

.limit-left .max-conditions {
  color: #e84935;
  font-size: 12px;
}
.limit-left .max-conditions div {
  text-align: center;
}

.limit-right {
  padding: 12px;
  flex: 1;
  font-size: 2vh;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.limit-right .title {
  overflow: hidden;
  min-height: 40px;
}

.limit-right .title .left {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
}

.limit-right .bottom {
  display: flex;
  align-items: center;
  font-weight: 400;
  bottom: 0.6vh;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
}

.limit-right .bottom .left {
  font-weight: 500;
  font-size: 11px;
  color: #D32D18;
  line-height: 16px;
  text-align: left;
  flex: 1;
}

.did-get .limit-right .bottom .left,
.did-select .limit-right .bottom .left {
  color: #666666;
}

.limit-right .bottom .right {
  cursor: pointer;
  width: 55px;
  height: 22px;
  color: #fff;
  background: linear-gradient(270deg, #E84935 0%, rgba(232, 73, 53, 0.6) 100%);
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.limit-right .bottom .right-get {
  cursor: pointer;
  width: 55px;
  height: 22px;
  color: #fff;
  background: #BDBDBD;
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}

.no-inventory {
  background: linear-gradient(270deg, #bdbdbd 0%, #e0d3d3 100%) !important;
}

.maxDiscountPrice {
  font-weight: 500;
  font-size: 10px;
  color: #ef7b6c;
}

.question {
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #999999;
  font-weight: normal;
}
</style>