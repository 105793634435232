export default [
  {
    path: "/verify",
    name: "Verify",
    meta: { title: "注册", hideTab: true },
    component: () => import("@/views/register/index.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: { title: "注册", hideTab: true },
    component: () => import("@/views/register/verifyregister.vue")
  },
  {
    path: "/rorgotPassword",
    name: "RorgotPassword",
    meta: { title: "忘记密码", hideTab: true },
    component: () => import("@/views/Forgotpassword/index.vue")
  },
  {
    path: "/resetpassword",
    name: "Resetpassword",
    meta: { title: "设置密码", hideTab: true },
    component: () => import("@/views/Forgotpassword/resetpassword.vue")
  },
  {
    path: "/user/address",
    name: "Address",
    component: () => import("@/views/addAddress/index.vue")
  },
  {
    path: "/user/addressEdit",
    name: "/user/addressEdit",
    component: () => import("@/views/addAddress/detail.vue")
  },
  {
    path: "/pay/:orderNo",
    name: "pay",
    component: () => import("@/views/order/pay.vue")
  },
  {
    path: "/payResult",
    name: "payResult",
    component: () => import("@/views/order/payResult.vue")
  },
  {
    path: "/user/orderDetail/:orderNo",
    name: "OrderDetail",
    component: () => import("@/views/order/detial.vue")
  },
  {
    path: "/user/information",
    name: "userinformation",
    meta: { title: "个人中心", hideTab: true },
    component: () => import("@/views/user/index.vue")
  },
  {
    path: "/setting",
    name: "Setting",
    meta: { title: "設置", hideTab: true },
    component: () => import("@/views/user/setting.vue")
  },
  {
    path: "/setpPayPass",
    name: "SetpPayPass",
    meta: { title: "設置", hideTab: true },
    component: () => import("@/views/user/setpPayPass.vue")
  },
  {
    path: "/infoPage",
    name: "InfoPage",
    meta: { title: "賬戶資料", hideTab: true },
    component: () => import("@/views/user/infoPage.vue")
  },
  {
    path: "/phoneVerify",
    name: "PhoneVerify",
    meta: { title: "手机号", hideTab: true },
    component: () => import("@/views/verify/phoneVerify.vue")
  },
  {
    path: "/emailVerify",
    name: "emailVerify",
    meta: { title: "邮箱", hideTab: true },
    component: () => import("@/views/verify/emailVerify.vue")
  },
  {
    path: "/clinicVerify",
    name: "clinicVerify",
    meta: { title: "診所信息", hideTab: true },
    component: () => import("@/views/verify/clinicVerify.vue")
  },
  {
    path: "/shareCode",
    name: "ShareCode",
    meta: { title: "邀请码", hideTab: true },
    component: () => import("@/views/shareCode/index.vue")
  },
  {
    path: "/coupon",
    name: "Coupon",
    meta: { title: "", hideTab: true },
    component: () => import("@/views/coupon/index.vue")
  },
  {
    path: "/myCoupon",
    name: "MyCoupon",
    meta: { title: "", hideTab: true },
    component: () => import("@/views/coupon/myCoupon.vue")
  },
  {
    path: "/invitationList",
    name: "InvitationList",
    meta: { title: "邀请列表", hideTab: true },
    component: () => import("@/views/shareCode/InvitationList.vue")
  },
  {
    path: "/adminRegister",
    name: "AdminRegister",
    meta: { title: "管理员创建", hideTab: true },
    component: () => import("@/views/adminRegister/index.vue")
  },
]
